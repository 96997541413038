import React, { useState } from 'react'
import PropTypes from 'prop-types'

import loadable from '@loadable/component'

const ReviewDisplay = loadable(() => import('./ReviewDisplay'))

const PageBuilderReviews = ({ block, raw, index, pageId }, props) => {
  const [showReviews, setShowReviews] = useState(3)
  const { title, specific, byType, topLine } = block

  return (
    <section
      className={`container grid grid-cols-12 mb-8 md:mb-24 relative ${
        index === 0 ? `mt-8 md:mt-12` : ``
      }`}
    >
      {topLine && (
        <hr className="border-gray-400 col-span-12 xl:col-span-10 xl:col-start-2 mb-16 mt-2 md:mt-8" />
      )}
      <div className="col-span-12 xl:col-span-10 xl:col-start-2">
        <a aria-label="Review container" href="#reviews" name="reviews">
          <h2 className="text-17 md:text-24 font-bold mb-4">{title}</h2>
        </a>

        <div className="mb-8">
          <ReviewDisplay
            byType={byType}
            specific={specific}
            showReviewContent
            reviewId={pageId}
          />
        </div>
      </div>
    </section>
  )
}

PageBuilderReviews.propTypes = {
  block: PropTypes.object,
  raw: PropTypes.object,
  index: PropTypes.number,
  pageId: PropTypes.string,
}

PageBuilderReviews.defaultProps = {
  block: {},
  raw: {},
  index: 0,
  pageId: '',
}

export default PageBuilderReviews
